module.exports = {
  environment: process.env.REACT_APP_ENV,
  baseURL: process.env.REACT_APP_API_URL,
  ssoFrontendURL: process.env.REACT_APP_SSO_FRONTEND_URL,
  ssoBackendURL: process.env.REACT_APP_SSO_BACKEND_URL,
  cookieDomain: process.env.REACT_APP_COOKIE_DOMAIN,
  hubURL: process.env.REACT_APP_HUB_BACKEND_URL,
  socketURL: process.env.REACT_APP_SOCKET_BACKEND_URL,
  helpURL: process.env.REACT_APP_HELPCMS_SERVICE_URL,
  billingURL: process.env.REACT_APP_BILLING_SERVICE_URL,
  rollbarAccessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  urls: {
    getStarted: {
      video: 'https://d3obkcvtnn2y76.cloudfront.net/tpai/videos/getting-started-with-tpai/getting-started-with-tpai.mp4',
      captions: 'https://d3obkcvtnn2y76.cloudfront.net/tpai/videos/getting-started-with-tpai/getting-started-with-tpai.vtt',
      thumbnail: 'https://d3obkcvtnn2y76.cloudfront.net/tpai/videos/getting-started-with-tpai/getting-started-with-tpai.png',
    },
  },
};
