// Hardcoded data
// const allAgents = [{}, {}, {}];
// const tickets = { active: 30, closed: 70 };
const columns = [
  {
    title: 'Country',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Requests',
    dataIndex: 'age',
    key: 'age',
  },
];
const data = [
  {
    key: '1',
    name: 'India',
    age: 320223,
    address: 'New York No. 1 Lake Park',
    tags: ['nice', 'developer'],
  },
  {
    key: '2',
    name: 'China',
    age: 42233,
    address: 'London No. 1 Lake Park',
    tags: ['loser'],
  },
  {
    key: '3',
    name: 'United States',
    age: 32233,
    address: 'Sydney No. 1 Lake Park',
    tags: ['cool', 'teacher'],
  },
  {
    key: '4',
    name: 'Brazil',
    age: 4222102,
    address: 'London No. 1 Lake Park',
    tags: ['loser'],
  },
];
const customerFeedback = { averageScore: 'N.A.' };
const requestsPerCountry = [
  { country: 'China', requests: 100 },
  { country: 'United States', requests: 230 },
  { country: 'India', requests: 150 },
];
const highRequestHours = [
  { hour: '00:00', requests: 20 },
  { hour: '01:00', requests: 40 },
  { hour: '02:00', requests: 30 },
  { hour: '03:00', requests: 10 },
  { hour: '04:00', requests: 20 },
  { hour: '05:00', requests: 10 },
  { hour: '06:00', requests: 30 },
  { hour: '07:00', requests: 10 },
  { hour: '08:00', requests: 30 },
  { hour: '09:00', requests: 40 },
  { hour: '10:00', requests: 90 },
  { hour: '11:00', requests: 10 },
  { hour: '12:00', requests: 30 },
  { hour: '13:00', requests: 10 },
  { hour: '14:00', requests: 60 },
  { hour: '15:00', requests: 10 },
  { hour: '16:00', requests: 60 },
  { hour: '17:00', requests: 30 },
  { hour: '18:00', requests: 70 },
  { hour: '19:00', requests: 50 },
  { hour: '20:00', requests: 80 },
  { hour: '21:00', requests: 10 },
  { hour: '22:00', requests: 20 },
  { hour: '23:00', requests: 40 },
];

const highRequestMonths = [
  { month: 'Jan', usage: 200 },
  { month: 'Feb', usage: 180 },
  { month: 'Mar', usage: 220 },
  { month: 'Apr', usage: 250 },
  { month: 'May', usage: 270 },
  { month: 'Jun', usage: 300 },
  { month: 'Jul', usage: 320 },
  { month: 'Aug', usage: 310 },
  { month: 'Sep', usage: 290 },
  { month: 'Oct', usage: 260 },
  { month: 'Nov', usage: 240 },
  { month: 'Dec', usage: 210 },
];
  // const activeSessions = { total: 80 };
const hourlyUsageOption = {
  xAxis: { type: 'category', data: highRequestHours.map((item) => item.hour) },
  yAxis: { type: 'value' },
  series: [{ data: highRequestHours.map((item) => item.requests), type: 'bar' }],
};

const monthlyUsageOption = {
  xAxis: { type: 'category', data: highRequestMonths.map((item) => item.month) },
  yAxis: { type: 'value' },
  series: [{ data: highRequestMonths.map((item) => item.usage), type: 'line', smooth: true }],
};

// Pie chart configuration for tickets
// const ticketOption = {
//   series: [
//     {
//       name: 'Tickets',
//       type: 'pie',
//       data: [
//         { value: tickets.active, name: 'Active Tickets' },
//         { value: tickets.closed, name: 'Closed Tickets' },
//       ],
//     },
//   ],
// };

module.exports = {
  columns,
  data,
  customerFeedback,
  requestsPerCountry,
  highRequestHours,
  highRequestMonths,
  hourlyUsageOption,
  monthlyUsageOption,
};
